import { RoleId } from '@wix/members-domain-ts';

import { BIEvent, Thunk, ThunkWithArgs } from '../../../types';
import { openModalWithCallback } from '../../../services/modal';
import { maybeNavigateToHomePage } from '../../../services/navigation';
import { emitBIEventWithPayload } from '../../../services/bi-event';
import { clearInitialDataCache } from '../common';
import { toggleIsEditingProfile } from '../profile-page';
import { manageBadges } from './badges';
import { joinCommunity, leaveCommunity } from './community';
import { modalRoleAction } from './modal-roles';
import { memberBlockMember } from './member-block-member';
import { PROFILE_ORIGIN } from '../../../constants/bi';

type CustomRolesActionsMap = { [key in RoleId]?: ThunkWithArgs<RoleId> };

const anonymousReport: Thunk =
  () =>
  async (_, __, { wixCodeApi }) => {
    wixCodeApi.user.promptLogin({}).catch(() => {});
  };

export const deleteMember: ThunkWithArgs<RoleId> =
  (roleId) => async (_, getState, extra) => {
    const state = getState();
    const { viewed } = getState().users;
    const { compId, platformAPIs, wixCodeApi, membersService, experiments } =
      extra;
    const payload = {};
    const onConfirm = async () => {
      await membersService.deleteMember(viewed.uid);
      clearInitialDataCache(state, extra);
      await maybeNavigateToHomePage(wixCodeApi);
    };

    openModalWithCallback({
      compId,
      modalType: roleId,
      payload,
      platformAPIs,
      wixCodeApi,
      experiments,
      onConfirm,
    });
  };

const customRolesActionsMap: CustomRolesActionsMap = {
  [RoleId.JOIN_COMMUNITY]: joinCommunity,
  [RoleId.LEAVE_COMMUNITY]: leaveCommunity,
  [RoleId.EDIT]: toggleIsEditingProfile,
  [RoleId.ANONYMOUS_REPORT]: anonymousReport,
  [RoleId.DELETE_MEMBER]: deleteMember,
  [RoleId.MANAGE_BADGES]: manageBadges,
  [RoleId.MEMBER_BLOCK_MEMBER]: memberBlockMember,
};

export const executeRoleAction: ThunkWithArgs<RoleId> =
  (roleId) => async (dispatch, getState, extra) => {
    const state = getState();
    const { viewed } = state.users;

    emitBIEventWithPayload({
      state: getState(),
      extra,
      biEvent: BIEvent.RoleActionClicked,
      payload: {
        action_type: roleId,
        member_chosen: viewed.uid,
        formOrigin: PROFILE_ORIGIN,
      },
    });

    const customRoleAction = customRolesActionsMap[roleId];
    if (customRoleAction) {
      await customRoleAction(roleId)(dispatch, getState, extra);
      return;
    }

    await modalRoleAction(roleId)(dispatch, getState, extra);
  };

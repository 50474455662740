import { RoleId } from '@wix/members-domain-ts';

import { BIEvent, Thunk } from '../../types';
import { PROFILE_ORIGIN } from '../../constants/bi';
import { emitBIEventWithPayload } from '../../services/bi-event';
import { Applications } from '../../services/public-api-store';
import { isMemberInCommunity } from '../selectors';
import { joinCommunity } from './role-action/community';

export const openChat: Thunk = () => async (dispatch, getState, extra) => {
  const state = getState();
  const { current, viewed } = state.users;
  const { wixCodeApi, getPublicAPI } = extra;

  if (!current) {
    wixCodeApi.user.promptLogin({}).catch(() => {});
    return;
  }

  if (!isMemberInCommunity(current)) {
    await joinCommunity(RoleId.JOIN_COMMUNITY)(dispatch, getState, extra);
    return;
  }

  const chatApi = await getPublicAPI(Applications.Chat);
  if (chatApi) {
    emitBIEventWithPayload({
      state,
      extra,
      biEvent: BIEvent.MembersChatOpened,
      payload: { origin: PROFILE_ORIGIN, messaged_member_id: viewed.uid },
    });
    chatApi.startPrivateChat(viewed.uid).catch(() => {});
  }
};
